<template>
  <div id="walton-scale">
    <h5>Walton & Gardner-Mewin Scale</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      v-if="getDataSuccess && handleLogicStatement('waltonGardnerMewinScaleInfo', 'visibility')"
    />

    <b-overlay :show="saveDataLoading">
      <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
        <!-- Date of Assessment -->
        <datepicker
          v-if="
            handleLogicStatement('dateOfWaltonGardnerMewinScale', 'visibility')
          "
          v-model="
            content.waltonGardnerMewinScale.dateOfWaltonGardnerMewinScale
          "
          label="Date of Assessment"
          name="dateOfWaltonGardnerMewinScale"
          placeholder="Enter Date of Assessment"
          :logic="logic"
          :errors="errors"
          :isDisable="handleLogicStatement('dateOfWaltonGardnerMewinScale', 'disable')"
          @changeEvent="updateData"
        />

        <!-- Scale -->

        <radiobutton
          v-if="
            handleLogicStatement('waltonGardnerMewinScaleGrade', 'visibility')
          "
          v-model="
            content.waltonGardnerMewinScale.waltonGardnerMewinScaleGrade
          "
          type="stacked"
          label="Select Grade"
          name="waltonGardnerMewinScaleGrade"
          :options="options.gardnerScale"
          :logic="logic"
          :errors="errors"
          @inputEvent="updateData"
        />

        <hr />

        <div class="text-center buttons mt-3">
          <button class="btn btn-sm btn-pompe-primary ml-2">Save & Continue</button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import WaltonGardnerMewinScaleMixin from './WaltonGardnerMewinScale.mixin';
import { $getInfoText } from '@/helper/globalFunction';

export default {
  mixins: [
    WaltonGardnerMewinScaleMixin
  ],  
  data: () => {
    return {
      infoText: '',
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "waltonGardnerMewinScale",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "waltonGardnerMewinScaleInfo",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
  },
};
</script>

<style></style>

<template>
  <div id="vital-signs">
    <h5>Vital Signs</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      v-if="getDataSuccess && handleLogicStatement('vitalSignsInfo', 'visibility')"
    />

    <b-overlay :show="saveDataLoading">
      <form v-if="getDataSuccess">
        <!-- Date of Assessment -->
        <datepicker
          v-if="handleLogicStatement('dateOfVitalSigns', 'visibility')"
          v-model="content.vitalSigns.dateOfVitalSigns"
          label="Date of Assessment"
          name="dateOfVitalSigns"
          placeholder="Enter Date of Assessment"
          :content="content"
          :logic="logic"
          :errors="errors"
          :isDisable="handleLogicStatement('dateOfVitalSigns', 'disable')"
          @changeEvent="updateData"
        />

        <!-- Height -->
        <div class="row">
          <div class="col-4">
            <decimal-input
              v-if="handleLogicStatement('heightInCM', 'visibility')"
              v-model="content.vitalSigns.heightInCM"
              label="Height (cm)"
              name="heightInCM"
              placeholder="Height (cm)"
              :logic="logic"
              :errors="errors"
              :content="content"
              @blurEvent="updateData"
            />
          </div>
          <div class="col">
            <checkbox
              v-if="
                handleLogicStatement('heightInCMNotPerformed', 'visibility')
              "
              v-model="content.vitalSigns.heightInCMNotPerformed"
              name="heightInCMNotPerformed"
              label="Is Value Known ?"
              :text="content.vitalSigns.heightInCMNotPerformed ? 'No': 'Yes'"
              type="switch"
              :logic="logic"
              :errors="errors"
              @inputEvent="eventOnInput('heightInCMNotPerformed', ['heightInCM'])"
            />
          </div>
        </div>

        <!-- Weight -->
        <div class="row">
          <div class="col-4">
            <text-field
              v-if="handleLogicStatement('weightInKG', 'visibility')"
              v-model="content.vitalSigns.weightInKG"
              label="Weight (Kg)"
              name="weightInKG"
              type="double"
              placeholder="Weight (Kg)"
              :logic="logic"
              :errors="errors"
              :content="content"
              @blurEvent="updateData"
            />
          </div>
          <div class="col">
            <checkbox
              v-if="
                handleLogicStatement('weightInKGNotPerformed', 'visibility')
              "
              v-model="content.vitalSigns.weightInKGNotPerformed"
              name="weightInKGNotPerformed"
              label="Is Value Known ?"
              :text="content.vitalSigns.weightInKGNotPerformed ? 'No': 'Yes'"
              type="switch"
              :logic="logic"
              :errors="errors"
              @inputEvent="eventOnInput('weightInKGNotPerformed', ['weightInKG'])"
            />
          </div>
        </div>

        <!-- Systolic Blood Pressure -->
        <div class="row">
          <div class="col-4">
            <text-field
              v-if="handleLogicStatement('systolicBloodPressure', 'visibility')"
              v-model="content.vitalSigns.systolicBloodPressure"
              label="Systolic Blood Pressure (mm Hg)"
              name="systolicBloodPressure"
              type="double"
              placeholder="Systolic Blood Pressure (mm Hg)"
              :logic="logic"
              :errors="errors"
              :content="content"
              @blurEvent="updateData"
            />
          </div>
          <div class="col">
            <checkbox
              v-if="
                handleLogicStatement(
                  'systolicBloodPressureNotPerformed',
                  'visibility'
                )
              "
              v-model="content.vitalSigns.systolicBloodPressureNotPerformed"
              name="systolicBloodPressureNotPerformed"
              label="Is Value Known ?"
              :text="
                content.vitalSigns.systolicBloodPressureNotPerformed
                  ? 'No'
                  : 'Yes'
              "
              type="switch"
              :logic="logic"
              :errors="errors"
              @inputEvent="eventOnInput(
                'systolicBloodPressureNotPerformed',
                ['systolicBloodPressure'])
              "
            />
          </div>
        </div>

        <!-- Diastolic Blood Pressure -->
        <div class="row">
          <div class="col-4">
            <text-field
              v-if="
                handleLogicStatement('diastolicBloodPressure', 'visibility')
              "
              v-model="content.vitalSigns.diastolicBloodPressure"
              type="double"
              label="Diastolic Blood Pressure (mm Hg)"
              name="diastolicBloodPressure"
              placeholder="Diastolic Blood Pressure (mm Hg)"
              :logic="logic"
              :errors="errors"
              :content="content"
              @blurEvent="updateData"
            />
          </div>
          <div class="col">
            <checkbox
              v-if="
                handleLogicStatement(
                  'diastolicBloodPressureNotPerformed',
                  'visibility'
                )
              "
              v-model="
                content.vitalSigns.diastolicBloodPressureNotPerformed
              "
              name="diastolicBloodPressureNotPerformed"
              label="Is Value Known ?"
              :text="
                content.vitalSigns.diastolicBloodPressureNotPerformed
                  ? 'No'
                  : 'Yes'
              "
              type="switch"
              :logic="logic"
              :errors="errors"
              @inputEvent="eventOnInput(
                'diastolicBloodPressureNotPerformed',
                ['diastolicBloodPressure'])
              "
            />
          </div>
        </div>

        <!-- Heart Rate -->
        <div class="row">
          <div class="col-4">
            <text-field
              v-if="handleLogicStatement('heartRate', 'visibility')"
              v-model="content.vitalSigns.heartRate"
              label="Heart Rate (bpm)"
              type="double"
              name="heartRate"
              placeholder="Heart Rate (bpm)"
              :content="content"
              :logic="logic"
              :errors="errors"
              @blurEvent="updateData"
            />
          </div>
          <div class="col">
            <checkbox
              v-if="handleLogicStatement('heartRateNotPerformed', 'visibility')"
              v-model="content.vitalSigns.heartRateNotPerformed"
              name="heartRateNotPerformed"
              label="Is Value Known ?"
              :text="content.vitalSigns.heartRateNotPerformed ? 'No' : 'Yes'"
              type="switch"
              :logic="logic"
              :errors="errors"
              @inputEvent="eventOnInput('heartRateNotPerformed', ['heartRate'])"
              @updateFieldValue="updateFieldValue"
            />
          </div>
        </div>

        <hr />

        <div class="text-center buttons mt-3">
          <button 
            class="btn btn-sm btn-pompe-primary ml-2" 
            type="button"
            @click="requestSaveForm"
          >
            Save & Continue
          </button>
        </div>
      </form>
    </b-overlay>

    <AuditBox 
      ref="auditBox" 
      :user-data="userData"
      :options="audit.options"
      :show-cancel="false"
      :loading="saveDataLoading"
      @submit="submitAudit"
      @close="refreshPage"
    />
  </div>
</template>

<script>

import DecimalInput from '@/components/reusable/FormFields/DecimalInput.vue';
import AuditBox from '@/components/functional/AuditBox/index.vue';

import VitalSignsMixin from './VitalSigns.mixin';

import { $getInfoText } from '@/helper/globalFunction';

export default {
  components: {
    DecimalInput,
    AuditBox,
  },
  props: {
    userData: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [
    VitalSignsMixin
  ],
  data: () => {
    return {
      infoText: '',
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
      confirmationState: null,
      audit: {
        content: {},
        options: [],
      }
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);

      this.checkFormStatus(
        null,
        "vitalSigns",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "vitalSignInfo",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
    eventOnInput(currentField, affectedField) {
      this.updateRelatedField(currentField, affectedField);
      this.updateData(currentField);
    },
    openModal() {
      this.$refs.auditBox.openModal();
    },
    refreshPage() {
      this.saveDataLoading = true;
      window.location.reload();
    },
     submitAudit({ 
      stateType, 
      stateOtherInfo 
    }) {
      this.saveDataLoading = true;

      let payload = {
        ...this.audit.content,
        stateType,
        stateOtherInfo,
      }

      this.$requestService
        .post(
          this.$apiEndpoints.getSpecificPage + `/auditAssessment?businessKey=${this.$route.params.id}`,
          payload,
          this.handleSaveAuditSuccess,
          e => console.error(e),
        )
        .then(() => {
          this.saveDataLoading = false;
        });
    },
    handleSaveAuditSuccess() {
      this.$router.push({
        name: 'summary',
        params: {
          tab: 'summaryPatientView'
        },
      })
    }
  },
};
</script>

<style></style>

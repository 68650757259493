<template>
  <div class="audit-box">
    <ModalTemplate 
      v-bind="config"
      :hide-header-close="loading"
      :back-drop-close="false"
      :esc-key-close="false"
      @modal:on-close="$emit('close')"
    >
      <template #modal-body>
        <div class="audit-box_confirmation-text">
          By pressing <b> confirm </b> the assessment with <b>ID</b>: <br>
          <b>{{ $route.params.id }}</b>

          <div class="mt-2">
            Will be signed by <br/>

            <b>{{ userData.firstName }} {{ userData.lastName }}</b>
          </div>

          <hr />

          <div class="form-group mt-3">
            <label>Justification</label>
            <select 
              class="form-control form-control-sm"
              :disabled="loading"
              @change="setState"
            >
              <option :value='null'> Select state </option>
              <option 
                v-for="(option, idx) in options"
                :key="idx"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
            <small 
              v-if="errors.state"
              class="text-danger"
            > 
              {{ errors.state }} 
            </small>
          </div>

          <div
            v-if="state === 'other'" 
            class="form-group"
          >
            <label>Other state</label>
            <input
              v-model="otherState"
              class="form-control form-control-sm"
            />
            <small 
              v-if="errors.otherState"
              class="text-danger"
            > 
              {{ errors.otherState }} 
            </small>
          </div>
        </div>
      </template>

      <template #modal-footer-custom>
        <button 
          type="button"
          :disabled="loading"
          class="btn btn-pompe-primary btn-sm"
          @click="submitConfirm"
        >
          {{ loading ? 'Please wait' : 'Save' }}
        </button>
        <button 
          v-if="showCancel"
          type="button"
          class="btn btn-danger btn-sm"
          @click="config.show = false"
        >
          Cancel
        </button>
      </template>
    </ModalTemplate>
  </div>
</template>

<script>
import ModalTemplate from '@/components/templates/ModalTemplate.vue';

export default {
  name: 'AuditBox',
  components: {
    ModalTemplate
  },
  props: {
    modalId: {
      type: String,
      default: '',
    },
    userData: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    state: null,
    otherState: null,
    config: {
      title: 'Confirmation',  
      size: 'md',
      show: false,
      escKeyClose: false,
      backDropClose: false,
    },
    errors: {
      state: '',
      otherState: '',
    },
  }),
  watch: {
    otherState: {
      handler(val) {
        if (val) this.errors.otherState = '';
      },
    }
  },
  methods: {
    setState(e) {
      this.state = e.target.value;
      if (this.state) this.errors.state = '';
    },
    openModal() {
      this.config.show = true;
    },
    submitConfirm() {
      if (!this.state) {
        this.errors.state = 'State cannot be empty';
        return false;
      } else if (this.state === 'other' && !this.otherState) {
        this.errors.otherState = 'Other state cannot be empty';
        return false;
      }

      this.$emit('submit', {
        stateType: this.state,
        stateOtherInfo: this.state === 'other' ? this.otherState : null,
      });

      this.config.show = false;
    },
  }
}
</script>

<style>

</style>

export default {
  methods: {
    dateFormatter() {
      this.content.respiratoryAssessment.dateOfRespiratoryAssessment =
        this.formatDate(
          this.content.respiratoryAssessment.dateOfRespiratoryAssessment
        );
      this.content.respiratoryAssessment.dateOfFirstUse = this.formatDate(
        this.content.respiratoryAssessment.dateOfFirstUse
      );
    },
  },
};

export default {
  methods: {
    dateFormatter() {
      this.content.motorFunctionalActivities.dateOfMotorFunctionalActivities =
        this.formatDate(
          this.content.motorFunctionalActivities.dateOfMotorFunctionalActivities
        );
    },
  },
};

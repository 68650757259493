export default {
  methods: {
    // for table and options
    constructArray() {
      this.options.mobilityScaleOpt.forEach((el, index) => {
        // for scale table
        if (index > 0)
          this.mRCScaleTable.push({
            text: el.text,
            value: el.value,
          });

        // for scale options
        if (el.value !== null)
          this.mRCScale.push({
            text: el.value,
            value: el.value,
          });
      });
    },
    dateFormatter() {
      this.content.mRCScale.dateOfMRCScale = this.formatDate(
        this.content.mRCScale.dateOfMRCScale
      );
    },
  }
}
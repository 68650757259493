export default {
  methods: {
    dateFormatter() {
      this.content.fatigueSeverityScale.dateOfFatigueSeverityScale =
        this.formatDate(
          this.content.fatigueSeverityScale.dateOfFatigueSeverityScale
        );
    },
  },
};

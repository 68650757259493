<template>
  <div id="motorFunctionalActivities">
    <h5>Motor Functional Activities</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      v-if="
        getDataSuccess &&
        handleLogicStatement('motorFunctionalActivitiesInfo', 'visibility')
      "
    />

    <notification
      type="info"
      :message="infoText2"
      v-if="
        getDataSuccess &&
        handleLogicStatement('motorFunctionalActivitiesInfo2', 'visibility')
      "
    />

    <b-overlay :show="saveDataLoading">
      <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
        <!-- Date of Assessment -->
        <datepicker
          v-if="
            handleLogicStatement(
              'dateOfMotorFunctionalActivities',
              'visibility'
            )
          "
          v-model="content.motorFunctionalActivities.dateOfMotorFunctionalActivities"
          label="Date of Assessment"
          name="dateOfMotorFunctionalActivities"
          placeholder="Enter Date of Assessment"
          :content="content"
          :logic="logic"
          :errors="errors"
          :isDisable="handleLogicStatement('dateOfMotorFunctionalActivities', 'disable')"
          @changeEvent="updateData"
        />

        <!--  Walking 10 Meters -->
        <div class="row">
          <div class="col">
            <decimal-input
              v-if="handleLogicStatement('walking10Meters', 'visibility')"
              v-model="content.motorFunctionalActivities.walking10Meters"
              label="Walking 10 Meters (sec)"
              name="walking10Meters"
              type="double"
              placeholder="Walking 10 Meters (sec)"
              :content="content"
              :logic="logic"
              :errors="errors"
              @blurEvent="updateData"
            />
          </div>
          <div class="col">
            <dropdown
              v-if="
                handleLogicStatement('walking10MetersAssistance', 'visibility')
              "
              v-model="content.motorFunctionalActivities.walking10MetersAssistance"
              label="Assistance"
              name="walking10MetersAssistance"
              :options="options.motorFuncitonalActivitiesOpt"
              :content="content"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
        </div>

        <hr />

        <div class="text-center buttons mt-3">
          <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
            Save & Continue
          </button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import MotorFunctionalActivitiesMixin from "./MotorFunctionalActivities.mixin";
import { $getInfoText } from '@/helper/globalFunction';

export default {
  mixins: [MotorFunctionalActivitiesMixin],
  data: () => {
    return {
      infoText: "",
      infoText2: "",
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "motorFunctionalActivities",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "motorFunctionalActivitiesInfo",
        this.options.info
      );

      this.infoText2 = this.$getInfoText(
        "motorFunctionalActivitiesInfo2",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
  },
};
</script>

<style></style>

export default {
  methods: {
    dateFormatter() {
      this.content.rotterdam9ItemHandicapScale.dateOfRotterdam9ItemHandicapScale =
        this.formatDate(
          this.content.rotterdam9ItemHandicapScale
            .dateOfRotterdam9ItemHandicapScale
        );
    },
  },
};

<template>
  <div id="muskoskeletal">
    <h5>Muscoskeletal / Motor Assessment</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      v-if="
        getDataSuccess &&
        handleLogicStatement('muscoskeletalMotorAssessmentInfo', 'visibility')
      "
      class="mb-3"
    />

    <b-overlay :show="saveDataLoading">
      <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
        <!-- Date of Assessment -->
        <datepicker
          v-if="
            handleLogicStatement(
              'dateOfmuscoskeletalMotorAssessment',
              'visibility'
            )
          "
          v-model="content.muscoskeletalMotorAssessment.dateOfmuscoskeletalMotorAssessment"
          label="Date of Assessment"
          name="dateOfmuscoskeletalMotorAssessment"
          placeholder="Enter Date of Assessment"
          :logic="logic"
          :errors="errors"
          :isDisable="handleLogicStatement(
            'dateOfmuscoskeletalMotorAssessment',
            'disable'
          )"
          @changeEvent="updateData"
        />

        <!-- Proximal muscle weakness in upper extremities -->
        <div class="row">
          <div class="col">
            <dropdown
              v-if="
                handleLogicStatement(
                  'proximalWeaknessInUpperExtremities.isPresent',
                  'visibility'
                )
              "
              v-model="content.muscoskeletalMotorAssessment.proximalWeaknessInUpperExtremities.isPresent"
              label="Proximal muscle weakness in upper extremities"
              name="proximalWeaknessInUpperExtremities.isPresent"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'proximalWeaknessInUpperExtremities.theStatus',
                'visibility'
              )
            "
          >
            <!-- Status -->
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .proximalWeaknessInUpperExtremities.theStatus
              "
              label="Status"
              name="proximalWeaknessInUpperExtremities.theStatus"
              :options="options.dropStatusList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'proximalWeaknessInUpperExtremities.dateIfNew',
                'visibility'
              )
            "
          >
            <!-- proximalMuscleWeaknessInUpperExtremitiesDate -->
            <datepicker
              v-model="
                content.muscoskeletalMotorAssessment
                  .proximalWeaknessInUpperExtremities.dateIfNew
              "
              label="Date of Diagnosis"
              name="proximalWeaknessInUpperExtremities.dateIfNew"
              placeholder="Enter Date of Diagnosis"
              :logic="logic"
              :errors="errors"
              :isDisable="handleLogicStatement(
                'proximalWeaknessInUpperExtremities.dateIfNew',
                'disable'
              )"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'proximalWeaknessInUpperExtremities.isPriorPompe',
                'visibility'
              )
            "
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .proximalWeaknessInUpperExtremities.isPriorPompe
              "
              label="Was diagnosis made prior to of Pompe Therapy?"
              name="proximalWeaknessInUpperExtremities.isPriorPompe"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
        </div>

        <!-- Distal muscle weakness in upper extremities -->
        <div class="row">
          <div class="col">
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities.isPresent
              "
              label="Distal muscle weakness in upper extremities"
              name="distalWeaknessInUpperExtremities.isPresent"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'distalWeaknessInUpperExtremities.theStatus',
                'visibility'
              )
            "
          >
            <!-- Status -->
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities.theStatus
              "
              label="Status"
              name="distalWeaknessInUpperExtremities.theStatus"
              :options="options.dropStatusList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'distalWeaknessInUpperExtremities.dateIfNew',
                'visibility'
              )
            "
          >
            <!-- distalMuscleWeaknessInUpperExtremitiesDate -->
            <datepicker
              v-model="
                content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities.dateIfNew
              "
              label="Date of Diagnosis"
              name="distalWeaknessInUpperExtremities.dateIfNew"
              placeholder="Enter Date of Diagnosis"
              :logic="logic"
              :errors="errors"
              :isDisable="handleLogicStatement(
                'distalWeaknessInUpperExtremities.dateIfNew',
                'disable'
              )"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'distalWeaknessInUpperExtremities.isPriorPompe',
                'visibility'
              )
            "
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .distalWeaknessInUpperExtremities.isPriorPompe
              "
              label="Was diagnosis made prior to of Pompe Therapy?"
              name="distalWeaknessInUpperExtremities.isPriorPompe"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
        </div>

        <!-- Proximal muscle weakness in lower extremities -->
        <div class="row">
          <div class="col">
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities.isPresent
              "
              label="Proximal muscle weakness in lower extremities"
              name="proximalWeaknessInLowerExtremities.isPresent"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'proximalWeaknessInLowerExtremities.theStatus',
                'visibility'
              )
            "
          >
            <!-- Status -->
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities.theStatus
              "
              label="Status"
              name="proximalWeaknessInLowerExtremities.theStatus"
              :options="options.dropStatusList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'proximalWeaknessInLowerExtremities.dateIfNew',
                'visibility'
              )
            "
          >
            <!-- proximalMuscleWeaknessInLowerExtremitiesDate -->
            <datepicker
              v-model="
                content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities.dateIfNew
              "
              label="Date of Diagnosis"
              name="proximalWeaknessInLowerExtremities.dateIfNew"
              placeholder="Enter Date of Diagnosis"
              :logic="logic"
              :errors="errors"
              :isDisable="handleLogicStatement(
                'proximalWeaknessInLowerExtremities.dateIfNew',
                'disable'
              )"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'proximalWeaknessInLowerExtremities.isPriorPompe',
                'visibility'
              )
            "
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .proximalWeaknessInLowerExtremities.isPriorPompe
              "
              label="Was diagnosis made prior to of Pompe Therapy?"
              name="proximalWeaknessInLowerExtremities.isPriorPompe"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
        </div>

        <!-- Distal muscle weakness in lower extremities -->
        <div class="row">
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'distalWeaknessInLowerExtremities.isPresent',
                'visibility'
              )
            "
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities.isPresent
              "
              label="Distal muscle weakness in lower extremities"
              name="distalWeaknessInLowerExtremities.isPresent"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'distalWeaknessInLowerExtremities.theStatus',
                'visibility'
              )
            "
          >
            <!-- Status -->
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities.theStatus
              "
              label="Status"
              name="distalWeaknessInLowerExtremities.theStatus"
              :options="options.dropStatusList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'distalWeaknessInLowerExtremities.dateIfNew',
                'visibility'
              )
            "
          >
            <!-- distalMuscleWeaknessInLowerExtremitiesDate -->
            <datepicker
              v-model="
                content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities.dateIfNew
              "
              label="Date of Diagnosis"
              name="distalWeaknessInLowerExtremities.dateIfNew"
              placeholder="Enter Date of Diagnosis"
              :logic="logic"
              :errors="errors"
              :isDisable="handleLogicStatement(
                'distalWeaknessInLowerExtremities.dateIfNew',
                'disable'
              )"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'distalWeaknessInLowerExtremities.isPriorPompe',
                'visibility'
              )
            "
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .distalWeaknessInLowerExtremities.isPriorPompe
              "
              label="Was diagnosis made prior to of Pompe Therapy?"
              name="distalWeaknessInLowerExtremities.isPriorPompe"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>  
        </div>

        <!-- Muscle weakness in trunk -->
        <div class="row">
          <div
            class="col"
            v-if="
              handleLogicStatement('weaknessInTrunk.isPresent', 'visibility')
            "
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment.weaknessInTrunk.isPresent
              "
              label="Muscle weakness in trunk"
              name="weaknessInTrunk.isPresent"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement('weaknessInTrunk.theStatus', 'visibility')
            "
          >
            <!-- Status -->
            <dropdown
              v-model="content.muscoskeletalMotorAssessment.weaknessInTrunk.theStatus"
              label="Status"
              name="weaknessInTrunk.theStatus"
              :options="options.dropStatusList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement('weaknessInTrunk.dateIfNew', 'visibility')
            "
          >
            <!-- muscleWeaknessInTrunkDate -->
            <datepicker
              v-model="content.muscoskeletalMotorAssessment.weaknessInTrunk.dateIfNew"
              label="Date of Diagnosis"
              name="weaknessInTrunk.dateIfNew"
              placeholder="Enter Date of Diagnosis"
              :logic="logic"
              :errors="errors"
              :isDisable="handleLogicStatement('weaknessInTrunk.dateIfNew', 'disable')"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement('weaknessInTrunk.isPriorPompe', 'visibility')
            "
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment.weaknessInTrunk
                  .isPriorPompe
              "
              label="Was diagnosis made prior to of Pompe Therapy?"
              name="weaknessInTrunk.isPriorPompe"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
        </div>

        <div class="row">
          <div
            class="col"
            v-if="
              handleLogicStatement('patientCurrentlyInAmbulatory', 'visibility')
            "
          >
            <!-- Is the patient currently ambulatory -->
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .patientCurrentlyInAmbulatory
              "
              label="Is the patient currently ambulatory"
              name="patientCurrentlyInAmbulatory"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="handleLogicStatement('patientAmbulatoryStatus', 'visibility')"
          >
            <!-- Status -->
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment.patientAmbulatoryStatus
              "
              label="Status"
              name="patientAmbulatoryStatus"
              :options="options.dropStatusList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'datePatientAmbulatoryDiagnosis',
                'visibility'
              )
            "
          >
            <!-- muscleWeaknessInTrunkDate -->
            <datepicker
              v-model="
                content.muscoskeletalMotorAssessment
                  .datePatientAmbulatoryDiagnosis
              "
              label="Date of Diagnosis"
              name="datePatientAmbulatoryDiagnosis"
              placeholder="Enter Date of Diagnosis"
              :logic="logic"
              :errors="errors"
              :isDisable="handleLogicStatement(
                'datePatientAmbulatoryDiagnosis',
                'disable'
              )"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="
              handleLogicStatement('patientAmbulatoryPriorPompe', 'visibility')
            "
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment.patientAmbulatoryPriorPompe
              "
              label="Was diagnosis made prior to of Pompe Therapy?"
              name="patientAmbulatoryPriorPompe"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
        </div>

        <!-- If Ambulatory is Yes -->
        <div
          class="row"
          v-if="
            handleLogicStatement(
              'patientAmbulatoryAssistanceType',
              'visibility'
            )
          "
        >
          <div class="col">
            <!-- Patient Ability -->
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .patientAmbulatoryAssistanceType
              "
              label="Ability"
              name="patientAmbulatoryAssistanceType"
              :options="options.abilityList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
        </div>

        <div class="row">
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'ambulationWasLostSinceTheLastAssessment',
                'visibility'
              )
            "
          >
            <!-- Ambulation was lost since the last assessment -->
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment.patientAmbulatoryLost
              "
              label="Ambulation was lost since the last assessment"
              name="patientAmbulatoryLost"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="handleLogicStatement('dateAmbulationWasLost', 'visibility')"
          >
            <!-- Date Ambulation Was Lost -->
            <datepicker
              v-model="
                content.muscoskeletalMotorAssessment.dateAmbulationWasLost
              "
              label="Date Ambulation Was Lost"
              name="dateAmbulationWasLost"
              placeholder="Enter Date of Diagnosis"
              :logic="logic"
              :errors="errors"
              :isDisable="handleLogicStatement(
                'dateAmbulationWasLost',
                'disable'
              )"
              @changeEvent="updateData"
            />
          </div>
        </div>

        <!-- Currently using ambulatory devices -->
        <div class="row">
          <div
            class="col"
            v-if="
              handleLogicStatement(
                'currentlyUsingAmbulatoryDevice',
                'visibility'
              )
            "
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment
                  .currentlyUsingAmbulatoryDevice
              "
              label="Currently using ambulatory devices"
              name="currentlyUsingAmbulatoryDevice"
              :options="options.dropList"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="handleLogicStatement('ambulatoryDevice', 'visibility')"
          >
            <dropdown
              v-model="
                content.muscoskeletalMotorAssessment.ambulatoryDevice
              "
              label="Ambulatory Device"
              name="ambulatoryDevice"
              :options="options.ambulatoryDevices"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>
        </div>

        <hr />

        <div class="text-center buttons mt-2">
          <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
            Save & Continue
          </button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import MuscoskeletalMotorMixin from './MuscoskeletalMotor.mixin';
import { $getInfoText } from '@/helper/globalFunction';

export default {
  mixins: [
    MuscoskeletalMotorMixin
  ],
  data: () => {
    return {
      infoText: "",
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "muscoskeletalMotorAssessment",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "muscoskeletalMotorAssessmentInfo",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // This will update field value
    customUpdateField(params) {
      const { field, value } = params

      if (field.includes(".")) {
        let fieldName = field.split(".");
        this.content.muscoskeletalMotorAssessment[fieldName[0]][fieldName[1]] =
          value;
      } else {
        this.content.muscoskeletalMotorAssessment[field] = value;
      }
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
  },
};
</script>

<style></style>

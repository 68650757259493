<template>
  <div id="rotterdam-scale">
    <h5>Rotterdam 9 Item Handicap Scale</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="
        infoText
      "
      class="mb-2"
      v-if="getDataSuccess && handleLogicStatement('rotterdam9ItemHandicapScaleInfo', 'visibility')"
    />

    <b-overlay :show="saveDataLoading">
      <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
        <!-- Date of Assessment -->
        <datepicker
          v-if="
            handleLogicStatement(
              'dateOfRotterdam9ItemHandicapScale',
              'visibility'
            )
          "
          v-model="
            content.rotterdam9ItemHandicapScale
              .dateOfRotterdam9ItemHandicapScale
          "
          label="Date of Assessment"
          name="dateOfRotterdam9ItemHandicapScale"
          placeholder="Enter Date of Assessment"
          :logic="logic"
          :errors="errors"
          :isDisable="handleLogicStatement(
            'dateOfRotterdam9ItemHandicapScale',
            'disable'
          )"
          @changeEvent="updateData"
        />

        <hr />

        <!-- Questions -->
        <b-form-group v-for="(item, idx) in options.questionList" :key="idx">
          <label>
            {{ item.no }}. {{ item.questionText }}
            <b-icon
              v-if="item.questionHint !== ''"
              class="ml-2"
              icon="exclamation-circle-fill"
              v-b-tooltip.bottom="item.questionHint"
            ></b-icon>
          </label>

          <dropdown
            v-if="handleLogicStatement(item.model, 'visibility')"
            v-model="content.rotterdam9ItemHandicapScale[item.model]"
            :name="item.model"
            :options="getOption(item.model)"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
            @updateFieldValue="updateFieldValue"
          />
          <!-- display error messages -->
          <error-message
            v-if="!$hasField(item.model, errors) && errors"
            :errors="errors"
            :fieldName="item.model"
          />
        </b-form-group>

        <hr />

        <div class="text-center buttons mt-3">
          <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
            Save & Continue
          </button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import rotterdamScale from '../../../../../helper/rotterdamQuestionScale';
import RotterdamScaleMixin from './RotterdamScale.mixin';
import { $hasField, $getInfoText } from '@/helper/globalFunction.js';

export default {
  mixins: [
    RotterdamScaleMixin
  ],
  data: () => {
    return {
      infoText: '',
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $hasField,
    $getInfoText,
    getOption(field) {
      switch (field) {
        case 'mobilityIndoors':
          return this.options.mobilityIndoors;
        case 'mobilityOutdoors':
          return this.options.mobilityOutdoors;
        case 'domesticTasksIndoors':
          return this.options.domesticTasksIndoors;
        case 'domesticTasksOutdoors':
          return this.options.domesticTasksOutdoors;
        case 'leisureActivitiesIndoors':
          return this.options.leisureActivitiesIndoors;
        case 'leisureActivitiesOutdoors':
          return this.options.leisureActivitiesOutdoors;
        case 'ableToDriveACarGoByBusRideABicycle':
          return this.options.ableToDriveACarGoByBusRideABicycle;
        case 'workStudy':
          return this.options.workStudy;
        case 'kitchenTasks': 
          return this.options.kitchenTasks;
        default:
          console.error('cannot get options');
      }
    },
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "rotterdam9ItemHandicapScale",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;
      this.options.questionList = rotterdamScale;

      this.infoText = this.$getInfoText(
        "rotterdam9ItemHandicapScaleInfo",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
  },
};
</script>

<style lang="scss"></style>

const questionScale = [
  {
    no: 1,
    questionText:
      "Mobility indoors: Are you able to move room to room, negotiating doors, carpets and polished surfaces?",
    questionHint:
      "Moving from room to room or outdoors does not necessarily mean that you have the ability to walk. For example, you can also move from one place to another in a wheelchair.",
    model: "mobilityIndoors",
  },
  {
    no: 2,
    questionText:
      "Mobility outdoors: Are you able to move outdoors from one place to another, negotiating curbs and uneven grounds?",
    questionHint:
      "Moving from room to room or outdoors does not necessarily mean that you have the ability to walk. For example, you can also move from one place to another in a wheelchair",
    model: "mobilityOutdoors",
  },
  {
    no: 3,
    questionText:
      "Kitchen tasks: Are you able to fulfill tasks such as making a pot of tea/coffee and serving it; are you able to collect items from a high and low cupboard, refrigerator, etc.?",
    questionHint: "Other kitchen tasks are also applicable",
    model: "kitchenTasks",
  },
  {
    no: 4,
    questionText:
      "Domestic tasks (indoors): Are you able to fulfill house-cleaning tasks, such as vacuum cleaning, dishwashing, doing the laundry, dusting, etc.?",
    questionHint: "",
    model: "domesticTasksIndoors",
  },
  {
    no: 5,
    questionText:
      "Domestic tasks (outdoors): Are you able to do the shopping, manage the garden, clean the car, etc.?",
    questionHint: "",
    model: "domesticTasksOutdoors",
  },
  {
    no: 6,
    questionText:
      "Leisure activities (indoors): Are you able to read a newspaper/magazine or a book, use the telephone, fulfill a hobby (other than sporting) ?",
    questionHint: "",
    model: "leisureActivitiesIndoors",
  },
  {
    no: 7,
    questionText:
      "Leisure activities (outdoors): Are you able to go to a party, theater, movies, concerts, museums, meetings, participate in sport ?",
    questionHint: "",
    model: "leisureActivitiesOutdoors",
  },
  {
    no: 8,
    questionText:
      "Able to drive a car/go by bus/ride a bicycle: Are you able to drive a car, go on a bus/subway, or ride a bicycle ?",
    questionHint:
      "For example, if you do not have a driver’s license, this part of the question can be considered as “being fulfilled,” unless it is clear that driving would be absolutely impossible due to your illness.",
    model: "ableToDriveACarGoByBusRideABicycle",
  },
  {
    no: 9,
    questionText:
      "Work/study: Are you able to fulfill your prior (before becoming ill) job/study ?",
    questionHint: "",
    model: "workStudy",
  },
];

export default questionScale;

export default {
  methods: {
    // format date
    dateFormatter() {
      // fields declaration
      let fields = [
        "dateOfmuscoskeletalMotorAssessment",
        "dateAmbulationWasLost",
        "datePatientAmbulatoryDiagnosis",
        "proximalWeaknessInUpperExtremities.dateIfNew",
        "proximalWeaknessInLowerExtremities.dateIfNew",
        "distalWeaknessInUpperExtremities.dateIfNew",
        "distalWeaknessInLowerExtremities.dateIfNew",
        "weaknessInTrunk.dateIfNew",
      ];

      // Iterate fields, and then format the date using date format mixin
      fields.forEach((el) => {
        if (el.includes(".")) {
          let fieldName = el.split(".");
          this.content.muscoskeletalMotorAssessment[fieldName[0]][
            fieldName[1]
          ] = this.formatDate(
            this.content.muscoskeletalMotorAssessment[fieldName[0]][
              fieldName[1]
            ]
          );
        } else {
          this.content.muscoskeletalMotorAssessment[el] = this.formatDate(
            this.content.muscoskeletalMotorAssessment[el]
          );
        }
      });
    },
  },
};

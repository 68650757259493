export default {
  methods: {
    dateFormatter() {
      this.content.modifiedRankingScale.dateOfModifiedRankingScale =
        this.formatDate(
          this.content.modifiedRankingScale.dateOfModifiedRankingScale
        );
    },
  },
};

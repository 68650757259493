<template>
  <div id="mrcScale">
    <h5>MRC Scale</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      class="mb-4"
      v-if="
        getDataSuccess && handleLogicStatement('mRCScaleInfo', 'visibility')
      "
    />

    <b-overlay :show="saveDataLoading">
      <form v-if="getDataSuccess" @submit.prevent="requestSaveForm">
        <div class="row">
          <div class="col">
            <!-- dateOfMRCScale -->
            <datepicker
              v-if="handleLogicStatement('dateOfMRCScale', 'visibility')"
              v-model="content.mRCScale.dateOfMRCScale"
              label="Date of MRC Scale"
              name="dateOfMRCScale"
              placeholder="Enter Date of MRC Scale"
              :content="content"
              :logic="logic"
              :errors="errors"
              :isDisable="handleLogicStatement('dateOfMRCScale', 'disable')"
              @changeEvent="updateData"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <perfect-scrollbar :options="{ suppressScrollX: true }">
              <div id="sitting">
                <h6> Sitting </h6>
                <!-- Shoulder Abduction -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('shoulderAbductionLeft', 'visibility')"
                      v-model="content.mRCScale.shoulderAbductionLeft"
                      label="Shoulder Abduction Left"
                      name="shoulderAbductionLeft"
                      :content="content"
                      :options="mRCScale"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('shoulderAbductionRight', 'visibility')"
                      v-model="content.mRCScale.shoulderAbductionRight"
                      label="Shoulder Abduction Right"
                      name="shoulderAbductionRight"
                      :content="content"
                      :options="mRCScale"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
                <!-- Elbow Flexion -->
                <div class="row">
                  <div class="col">
                    <!-- Left-->
                    <dropdown
                      v-if="handleLogicStatement('elbowFlexionLeft', 'visibility')"
                      v-model="content.mRCScale.elbowFlexionLeft"
                      label="Elbow Flexion Left"
                      name="elbowFlexionLeft"
                      :content="content"
                      :options="mRCScale"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('elbowFlexionRight', 'visibility')"
                      v-model="content.mRCScale.elbowFlexionRight"
                      label="Elbow Flexion Right"
                      name="elbowFlexionRight"
                      :content="content"
                      :options="mRCScale"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
                <!-- Wrist Flexion -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('wristFlexionLeft', 'visibility')"
                      v-model="content.mRCScale.wristFlexionLeft"
                      label="Wrist Flexion Left"
                      name="wristFlexionLeft"
                      :content="content"
                      :options="mRCScale"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('wristFlexionRight', 'visibility')"
                      v-model="content.mRCScale.wristFlexionRight"
                      label="Wrist Flexion Right"
                      name="wristFlexionRight"
                      :content="content"
                      :options="mRCScale"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
                <!-- Thumb Abduction -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('thumbAbductionLeft', 'visibility')"
                      v-model="content.mRCScale.thumbAbductionLeft"
                      label="Thumb Abduction Left"
                      name="thumbAbductionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('thumbAbductionRight', 'visibility')"
                      v-model="content.mRCScale.thumbAbductionRight"
                      label="Thumb Abduction Right"
                      name="thumbAbductionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
                <!-- Hip Flexion -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('hipFlexionLeft', 'visibility')"
                      v-model="content.mRCScale.hipFlexionLeft"
                      label="Hip Flexion Left"
                      name="hipFlexionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right-->
                    <dropdown
                      v-if="handleLogicStatement('hipFlexionRight', 'visibility')"
                      v-model="content.mRCScale.hipFlexionRight"
                      label="Hip Flexion Right"
                      name="hipFlexionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
                <!-- Knee Extension -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('kneeExtensionLeft', 'visibility')"
                      v-model="content.mRCScale.kneeExtensionLeft"
                      label="Knee Extension Left"
                      name="kneeExtensionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('kneeExtensionRight', 'visibility')"
                      v-model="content.mRCScale.kneeExtensionRight"
                      label="Knee Extension Right"
                      name="kneeExtensionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
                <!-- Ankle Dorsiflexion -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('ankleDorsiflexionLeft', 'visibility')"
                      v-model="content.mRCScale.ankleDorsiflexionLeft"
                      label="Ankle Dorsiflexion Left"
                      name="ankleDorsiflexionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('ankleDorsiflexionRight', 'visibility')"
                      v-model="content.mRCScale.ankleDorsiflexionRight"
                      label="Ankle Dorsiflexion Right"
                      name="ankleDorsiflexionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
                <!-- Ankle Eversion -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('ankleEversionLeft', 'visibility')"
                      v-model="content.mRCScale.ankleEversionLeft"
                      label="Ankle Eversion Left"
                      name="ankleEversionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('ankleEversionRight', 'visibility')"
                      v-model="content.mRCScale.ankleEversionRight"
                      label="Ankle Eversion Right"
                      name="ankleEversionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
                <!-- Ankle Inversion -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('ankleInversionLeft', 'visibility')"
                      v-model="content.mRCScale.ankleInversionLeft"
                      label="Ankle Inversion Left"
                      name="ankleInversionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('ankleInversionRight', 'visibility')"
                      v-model="content.mRCScale.ankleInversionRight"
                      label="Ankle Inversion Right"
                      name="ankleInversionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
              </div>  

              <br />

              <div id="prone">
                <h6>Prone</h6>
                <hr />

                <!-- Neck Extension -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('neckExtension', 'visibility')"
                      v-model="content.mRCScale.neckExtension"
                      label="Neck Extension"
                      name="neckExtension"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>

                <!-- Shoulder External Rotation -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="
                        handleLogicStatement(
                          'shoulderExternalRotationLeft',
                          'visibility'
                        )
                      "
                      v-model="content.mRCScale.shoulderExternalRotationLeft"
                      label="Shoulder External Rotation Left"
                      name="shoulderExternalRotationLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="
                        handleLogicStatement(
                          'shoulderExternalRotationRight',
                          'visibility'
                        )
                      "
                      v-model="content.mRCScale.shoulderExternalRotationRight"
                      label="Shoulder External Rotation Right"
                      name="shoulderExternalRotationRight"                      
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>

                <!-- Knee Flexion -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('kneeFlexionLeft', 'visibility')"
                      v-model="content.mRCScale.kneeFlexionLeft"
                      label="Knee Flexion Left"
                      name="kneeFlexionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('kneeFlexionRight', 'visibility')"
                      v-model="content.mRCScale.kneeFlexionRight"
                      label="Knee Flexion Right"
                      name="kneeFlexionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>

                <!-- Ankle Plantar Flexion -->
                <div class="row">
                  <!-- Left -->
                  <div class="col">
                    <dropdown
                      v-if="handleLogicStatement('anklePlantarFlexionLeft', 'visibility')"
                      v-model="content.mRCScale.anklePlantarFlexionLeft"
                      label="Ankle Plantar Flexion Left"
                      name="anklePlantarFlexionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <!-- Right -->
                  <div class="col">
                    <dropdown
                      v-if="handleLogicStatement('anklePlantarFlexionRight', 'visibility')"
                      v-model="content.mRCScale.anklePlantarFlexionRight"
                      label="Ankle Plantar Flexion Right"
                      name="anklePlantarFlexionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>

                <!-- Hip Extension -->
                <div class="row">
                  <!-- Left -->
                  <div class="col">
                    <dropdown
                      v-if="handleLogicStatement('hipExtensionLeft', 'visibility')"
                      v-model="content.mRCScale.hipExtensionLeft"
                      label="Hip Extension Left"
                      name="hipExtensionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <!-- Right -->
                  <div class="col">
                    <dropdown
                      v-if="handleLogicStatement('hipExtensionRight', 'visibility')"
                      v-model="content.mRCScale.hipExtensionRight"
                      label="Hip Extension Right"
                      name="hipExtensionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
              </div>

              <br />

              <div id="side-lying">
                <h6>Side Lying</h6>
                <hr />

                <!-- Hip Abduction -->
                <div class="row">
                  <div class="col">
                    <!-- Left -->
                    <dropdown
                      v-if="handleLogicStatement('hipAbductionLeft', 'visibility')"
                      v-model="content.mRCScale.hipAbductionLeft"
                      label="Hip Abduction Left"
                      name="hipAbductionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <div class="col">
                    <!-- Right -->
                    <dropdown
                      v-if="handleLogicStatement('hipAbductionRight', 'visibility')"
                      v-model="content.mRCScale.hipAbductionRight"
                      label="Hip Abduction Right"
                      name="hipAbductionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>

                <!-- Hip Adduction -->
                <div class="row">
                  <!-- Left -->
                  <div class="col">
                    <dropdown
                      v-if="handleLogicStatement('hipAdductionLeft', 'visibility')"
                      v-model="content.mRCScale.hipAdductionLeft"
                      label="Hip Adduction Left"
                      name="hipAdductionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <!-- Right -->
                  <div class="col">
                    <dropdown
                      v-if="handleLogicStatement('hipAdductionRight', 'visibility')"
                      v-model="content.mRCScale.hipAdductionRight"
                      label="Hip Adduction Right"
                      name="hipAdductionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
              </div>

              <br />

              <div id="supine">
                <h6> Supine </h6>
                <hr />

                <!-- Elbow Extension -->
                <div class="row">
                  <!-- Left -->
                  <div class="col">
                    <dropdown
                      v-if="handleLogicStatement('elbowExtensionLeft', 'visibility')"
                      v-model="content.mRCScale.elbowExtensionLeft"
                      label="Elbow Extension Left"
                      name="elbowExtensionLeft"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                  <!-- Right -->
                  <div class="col">
                    <dropdown
                      v-if="handleLogicStatement('elbowExtensionRight', 'visibility')"
                      v-model="content.mRCScale.elbowExtensionRight"
                      label="Elbow Extension Right"
                      name="elbowExtensionRight"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>

                <!-- Neck Flexion -->
                <div class="row">
                  <div class="col">
                    <dropdown
                      v-if="handleLogicStatement('neckFlexion', 'visibility')"
                      v-model="content.mRCScale.neckFlexion"
                      label="Neck Flexion"
                      name="neckFlexion"
                      :options="mRCScale"
                      :content="content"
                      :logic="logic"
                      :errors="errors"
                      @changeEvent="updateData"
                    />
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="col-4">
            <b-table
              thead-class="th-label"
              tbody-class="tr-label"
              striped
              small
              bordered
              :items="mRCScaleTable"
              :fields="fields"
            ></b-table>
          </div>
        </div>

        <hr />

        <div class="text-center buttons mt-3">
          <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
            Save & Continue
          </button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import MRCScaleMixin from './MRCScale.mixin';
import { $getInfoText } from '@/helper/globalFunction';

export default {
  mixins: [
    MRCScaleMixin
  ],
  data: () => {
    return {
      infoText: "",
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
      mRCScale: [{
        text: 'Please Select',
        value: null
      }],
      mRCScaleTable: [],
      fields: [
        {
          key: "text",
          label: "Description",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(null, "mRCScale", "GET", response.data.nav);

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.constructArray(); // for table and options value
      this.dateFormatter();
      this.infoText = this.$getInfoText("mRCScaleInfo", this.options.info);
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.requestUpdateForm();
    },
  },
};
</script>

<style>
.ps {
  height: 660px;
}
</style>
